import { Customer } from "shared";
import { useIsPolicyHolder } from "../../app/Providers/PolicyContext";
import { Address } from "./Address";

type SettingsProps = {
  customer: Customer;
};

/** Renders the account settings */
export const Settings = ({ customer }: SettingsProps) => {
  const { isPolicyHolder } = useIsPolicyHolder();

  return (
    <div className="container container_center">
      <main id="main">
        <h1 aria-label="Account Settings" className="section__title">
          Your account settings
        </h1>
        <div className="content">
          <h3>Email</h3>
          <span>{customer.email}</span>
          <h3>Name</h3>
          <span>
            {customer.firstName} {customer.lastName}
          </span>
          <p>isPolicyHolder: {isPolicyHolder?.toString() ?? "Undefined"}</p>
          <h3>Address</h3>
          <Address
            line1={customer.line1}
            line2={customer.line2}
            city={customer.city}
            state={customer.state}
            postalCode={customer.postalCode}
          />
        </div>
      </main>
    </div>
  );
};
