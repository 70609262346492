import { State } from "@hagerty/self-service-components/api/types";
import { getStateName } from ".";

type Headings = {
  heading: string;
  accessibleHeading: string;
};

export const partnerMap: Record<string, Headings> = {
  "state-farm": {
    heading: "State Farm Classic+®",
    accessibleHeading: "State Farm Classic Policy",
  },
};

export function getPolicyName(state: State, isApexPolicy: boolean): string {
  return `${getStateName(state)}${isApexPolicy ? " Enthusiast+" : ""}`;
}

export function getDefaultPartnerHeadings(
  state: State,
  isApexPolicy: boolean
): Headings {
  const heading = `${getPolicyName(state, isApexPolicy)} Policy`;
  return {
    heading,
    accessibleHeading: heading,
  };
}

export const getPartnerHeadings = (
  partner: string | undefined = "",
  state: State,
  isApexPolicy: boolean
): Headings => {
  return partnerMap[partner] ?? getDefaultPartnerHeadings(state, isApexPolicy);
};
