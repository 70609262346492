import { useLogHasESignDocuments } from "../../app/Providers/LogOnReadyContext";
import {
  Alerts as AlertsResponse,
  getPolicyName,
  getStateName,
  InsuranceBillAlert,
  phoneNumbers,
  removePolicyVersion,
} from "shared";
import { AlertLevel } from "../Alert";
import { UseESign } from "./useESign";
import { useUrls } from "../../app/Providers/AppContext";
import { useFetchApi } from "../../hooks/useFetchApi";
import { TagAlert } from "../../types/Tag.types";
import { useMemo, useRef } from "react";

import { mt } from "../../utils/tracking";
import { ActionConfig } from "./ActionConfig.types";
import { PleaseCall } from "./PleaseCall";

const HDCLevels = ["HDC1DOT0", "HDC1DOT5"];
const signLabel = "Sign documents";
const callMessage = "Please call";

function getCallHagertyActionConfig(
  phone: string,
  ariaLabel: string
): ActionConfig {
  return {
    ariaLabel,
    href: `tel:+${phone}`,
    text: "Call now",
    tagAction: {
      actionName: "call_us",
      actionType: "link",
    },
  };
}

function getPayBillActionConfig(
  payBillUrl: string,
  ariaLabel: string
): ActionConfig {
  const buttonText = "Pay now";
  return {
    ariaLabel,
    text: buttonText,
    href: payBillUrl,
    showLoader: true,
    tagAction: {
      actionName: buttonText,
      actionType: "link",
    },
  };
}

function getInsuranceBillAlertPolicyDesc(alert: InsuranceBillAlert): string {
  const policyName = getPolicyName(alert.state, alert.source === "apex");
  const formattedPolicyNumber = removePolicyVersion(alert.policyNumber);

  return `${policyName} policy #${formattedPolicyNumber}`;
}

export type Alert = {
  level: AlertLevel;

  /** Title, displayed in bold when there's a single alert */
  title: React.ReactNode;

  /** Optional message displayed after the title. Not bold. */
  message?: React.ReactNode;

  /** Optional button or link config. Displays on the right. */
  actionConfig?: ActionConfig;
};

// Fetch alerts on load. Returns an array of Alerts to normalize the data structure for rendering alerts
export function useAlerts(sendESignDocument: UseESign["send"]): Alert[] {
  const trackedAlerts = useRef<string[]>([]);
  const urls = useUrls();
  const logHasESignDocuments = useLogHasESignDocuments();

  // Prevent alert from being logged multiple times due to subsequent renders
  const trackAlert = ({ alertType, alertDetails }: TagAlert) => {
    if (!trackedAlerts.current.includes(alertType)) {
      mt.trackAlert({ alertType, alertDetails });
      trackedAlerts.current = [...trackedAlerts.current, alertType];
    }
  };

  // We want to fetch those alerts only once, that is the reason why I memoize it. This hook is re-rending every time when something has changed
  // on useESign hook, so it was causing issues with blinking/hidden esign modal. In addition, /alerts request is a bit heavy (takes some time).
  // We also do not refresh those alerts, so I decided to use memoization to fix issue with blinking modal
  const { data: alertsResponse } = useMemo(
    () => useFetchApi<AlertsResponse>("alerts", 2),
    []
  );

  const {
    coreBills = [],
    insuranceAutopayFailed = [],
    insuranceBillPastDue = [],
    hdcAutopayFailed,
    hdcBillPastDue,
    eSignDocuments = [],
  } = alertsResponse;

  logHasESignDocuments(eSignDocuments.length > 0);

  const alerts: Alert[] = [];

  if (hdcAutopayFailed) {
    const title = "Payment for Drivers Club membership failed to process.";
    const phone = phoneNumbers.hdc;
    const ariaLabel = `${title} Please call us at ${phone}`;

    trackAlert({ alertType: title, alertDetails: callMessage });

    alerts.push({
      title,
      level: "danger",
      message: <PleaseCall phoneNumber={phone} />,
      actionConfig: getCallHagertyActionConfig(phone, ariaLabel),
    });
  }

  if (hdcBillPastDue) {
    const title = "Payment for Drivers Club membership is past due.";
    const message = "Please make a payment.";
    const ariaLabel = `${title} Pay now.`;
    const matchingBill = coreBills.find(({ category }) =>
      HDCLevels.includes(category)
    );

    const paymentUrl = matchingBill?.paymentUrl ?? urls.billing.managePayments;

    trackAlert({ alertType: title, alertDetails: message });

    alerts.push({
      title,
      message,
      level: "danger",
      actionConfig: getPayBillActionConfig(paymentUrl, ariaLabel),
    });
  }

  insuranceAutopayFailed.forEach((alert) => {
    const policyDescription = getInsuranceBillAlertPolicyDesc(alert);
    const title = `Auto pay for ${policyDescription} failed to process.`;
    const phone =
      alert.source === "core"
        ? phoneNumbers.insurancePaymentError(alert.country)
        : phoneNumbers.apex;
    const ariaLabel = `${title} Please call us at ${phone}`;

    trackAlert({ alertType: title, alertDetails: callMessage });

    alerts.push({
      title,
      level: "danger",
      message: <PleaseCall phoneNumber={phone} />,
      actionConfig: getCallHagertyActionConfig(phone, ariaLabel),
    });
  });

  insuranceBillPastDue.forEach((alert) => {
    const policyDescription = getInsuranceBillAlertPolicyDesc(alert);
    const title = `Bill for ${policyDescription} is past due.`;

    if (alert.source === "core") {
      const message = "Please make a payment.";
      const bill = coreBills.find(
        (bill) => bill.formattedProductNumber === alert.policyNumber
      );
      const paymentUrl = bill?.paymentUrl ?? urls.billing.managePayments;
      const ariaLabel = `${title} Pay now.`;

      trackAlert({ alertType: title, alertDetails: message });

      alerts.push({
        title,
        message,
        level: "danger",
        actionConfig: getPayBillActionConfig(paymentUrl, ariaLabel),
      });
    } else {
      const phone = phoneNumbers.apex;
      const ariaLabel = `${title} Please call us at ${phone}`;

      trackAlert({ alertType: title, alertDetails: callMessage });

      alerts.push({
        title,
        level: "danger",
        message: <PleaseCall phoneNumber={phone} />,
        actionConfig: getCallHagertyActionConfig(phone, ariaLabel),
      });
    }
  });

  eSignDocuments.forEach((eSignDocument) => {
    const stateName = getStateName(eSignDocument.state);
    const title = "Signature required.";
    const message = `Please sign the documents to finalize the ${stateName} policy change.`;

    trackAlert({ alertType: title, alertDetails: message });

    alerts.push({
      title,
      message,
      actionConfig: {
        text: signLabel,
        ariaLabel: `E-Sign the ${stateName} policy change documents.`,
        onClick: () => sendESignDocument(eSignDocument),
        tagAction: {
          actionName: signLabel,
          actionType: "button",
        },
      },
      level: "warning",
    });
  });

  return alerts;
}
