import { Policy } from "@hagerty/self-service-components/api/types";
import { InsuranceLayout } from "./InsuranceLayout";
import { InsuranceTiles } from "./InsuranceTiles";
import { VehiclesListCard } from "../PolicyDetails/VehiclesListCard/VehiclesListCard";
import styles from "./InsurancePolicy.module.scss";

type InsurancePolicyProps = {
  policy: Policy;
};

/** Renders the "Hagerty Insurance Policy" region */
export const InsurancePolicy = ({ policy }: InsurancePolicyProps) => {
  return (
    <InsuranceLayout policy={policy}>
      <InsuranceTiles policy={policy} />
      <VehiclesListCard policy={policy} className={styles.spacing} />
    </InsuranceLayout>
  );
};
