import { Tile } from "../Tile";
import { ReactComponent as DownChevron } from "./img/chevron-down.svg";
import { Claims, IdCard, Edit, Documents } from "../../svg";
import { useUrls } from "../../app/Providers/AppContext";
import { getPolicyName } from "shared";
import { getVehiclesLabel } from "../../utils/vehicleUtils";
import styles from "./InsuranceTiles.module.scss";
import { TagAction } from "../../types/Tag.types";
import { useDialog } from "../Dialog";
import { CompOnlyDialog } from "./CompOnlyDialog";
import { Policy } from "@hagerty/self-service-components/api/types";
import { Popover } from "@hagerty/self-service-components";
import { useEffect, useRef, useState } from "react";
import { Card } from "../Card";
import { useDownloadIdCard } from "./useDownloadIdCard";
import { useDownloadDocument } from "./useDownloadDocument";
import { getFileClaimUrl } from "../../utils/urlUtils";
import { DownloadDocumentErrorAlert } from "./DownloadDocumentErrorAlert";
import { Link } from "../Link";
import { routes } from "../../routing/routes";

type InsuranceTilesProps = {
  policy: Policy;
};

const idTagAction: TagAction = {
  actionName: "Insurance ID",
  actionType: "button",
};
const decPageTagAction: TagAction = {
  actionName: "Declaration Page",
  actionType: "button",
};
const policyDocumentsTagAction: TagAction = {
  actionName: "Policy documents",
  actionType: "link",
};
const managePolicyTagAction: TagAction = {
  actionName: "Manage policy",
  actionType: "link",
};
const fileClaimTagAction: TagAction = {
  actionType: "link",
  actionName: "File a claim",
};
const claimStatusTagAction: TagAction = {
  actionType: "link",
  actionName: "Claim status",
};

export const InsuranceTiles = ({ policy }: InsuranceTilesProps) => {
  const [redirectClaims, setRedirectClaims] = useState(false);
  const [redirectPolicyDocument, setRedirectPolicyDocument] = useState(false);
  const triggerRef = useRef<HTMLElement>(null);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const urls = useUrls();
  const vehicleNamesLabel = getVehiclesLabel(policy.vehicles);
  const compOnlyDialog = useDialog();
  const [errorAlert, setErrorAlert] = useState<string | undefined>();
  const isCorePolicy = policy.source === "core";
  const { policyNumber, country } = policy;
  const partner = isCorePolicy ? policy.partner : undefined;
  const policyName = getPolicyName(policy.state, !isCorePolicy);
  const isStateFarm = partner === "state-farm";

  const {
    download: downloadIdCard,
    isDownloading: isDownloadingIdCard,
    error: downloadIdCardError,
  } = isCorePolicy
    ? useDownloadIdCard(policyNumber, country)
    : useDownloadDocument(policyNumber, "apex", "id-card");

  const {
    download: downloadDecPage,
    isDownloading: isDownloadingDecPage,
    error: downloadDecPageError,
  } = useDownloadDocument(policyNumber, policy.source, "dec-page");

  const claimStatusUrl = urls.insurance.fileClaim(policyNumber, country);
  const managePolicyUrl = routes.policyDetails(policyNumber);

  const hidePopover = () => {
    setIsPopoverOpen(false);
    triggerRef?.current?.blur();
  };

  const onClickClaimsLink = () => {
    hidePopover();
    setRedirectClaims(true);
  };

  useEffect(() => {
    if (downloadIdCardError) {
      setErrorAlert("ID Card");
    }
  }, [downloadIdCardError]);

  useEffect(() => {
    if (downloadDecPageError) {
      setErrorAlert("Declaration Page");
    }
  }, [downloadDecPageError]);

  const displayIdCardTile = isCorePolicy || policy.hasIdCard;

  return (
    <>
      <div className={styles.policyTiles}>
        {displayIdCardTile && (
          <Tile
            icon={<img src={IdCard} alt="Insurance ID" aria-hidden="true" />}
            isLoading={isDownloadingIdCard}
            className={styles.tile}
            cta="Insurance ID"
            ariaLabel={`Access Insurance ID for your ${vehicleNamesLabel} on policy ${policyNumber}`}
            tagAction={idTagAction}
            onClick={() => {
              setErrorAlert(undefined);
              policy.hasIdCard ? downloadIdCard() : compOnlyDialog.open();
            }}
          />
        )}

        {!isCorePolicy && (
          <Tile
            icon={
              <img src={Documents} alt="Declaration page" aria-hidden="true" />
            }
            isLoading={isDownloadingDecPage}
            className={styles.tile}
            cta="Declaration Page"
            ariaLabel={`Declaration page for policy ${policyNumber}`}
            tagAction={decPageTagAction}
            onClick={() => {
              setErrorAlert(undefined);
              downloadDecPage();
            }}
          />
        )}

        {isCorePolicy && (
          <>
            {country === "USA" && (
              <>
                <Tile
                  icon={
                    <img src={Claims} alt="Manage claims" aria-hidden="true" />
                  }
                  className={styles.tile}
                  cta="Claims"
                  ariaLabel={`Manage claims for your ${vehicleNamesLabel} on policy ${policyNumber}`}
                  onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                  isLoading={redirectClaims}
                  ctaIcon={<DownChevron />}
                  ref={triggerRef}
                />
                <Popover
                  triggerRef={triggerRef}
                  onClose={() => setTimeout(hidePopover)}
                  isOpen={isPopoverOpen}
                  placement={"bottom start"}
                  className={styles.popover}
                  restoreFocus={false}
                >
                  <Card className={styles.card}>
                    <Link
                      tagAction={fileClaimTagAction}
                      onClick={onClickClaimsLink}
                      href={getFileClaimUrl(policy, urls)}
                      isLoading={false}
                    >
                      File a claim
                    </Link>
                    <Link
                      tagAction={claimStatusTagAction}
                      onClick={onClickClaimsLink}
                      href={claimStatusUrl}
                    >
                      Claim status
                    </Link>
                  </Card>
                </Popover>
              </>
            )}

            {country === "CANADA" && (
              <Tile
                icon={
                  <img src={Claims} alt="File a claim" aria-hidden="true" />
                }
                className={styles.tile}
                cta="File a claim"
                ariaLabel={`File a claim for your ${vehicleNamesLabel} on policy ${policyNumber}`}
                href={getFileClaimUrl(policy, urls)}
                tagAction={fileClaimTagAction}
                onClick={onClickClaimsLink}
                isLoading={redirectClaims}
              />
            )}

            <Tile
              icon={
                <img
                  src={Documents}
                  alt="Policy documents"
                  aria-hidden="true"
                />
              }
              className={styles.tile}
              cta="Policy documents"
              ariaLabel={`Policy documents for policy ${policyNumber}`}
              href={urls.insurance.viewPolicy(
                policyNumber,
                country,
                isStateFarm
              )}
              tagAction={policyDocumentsTagAction}
              onClick={() => setRedirectPolicyDocument(true)}
              isLoading={redirectPolicyDocument}
            />

            {!isStateFarm && (
              <Tile
                icon={<img src={Edit} alt="Manage policy" aria-hidden="true" />}
                className={styles.tile}
                cta="Manage policy"
                ariaLabel={`Manage policy ${policyNumber} for your ${vehicleNamesLabel}`}
                href={managePolicyUrl}
                tagAction={managePolicyTagAction}
              />
            )}
          </>
        )}
      </div>

      {compOnlyDialog.isOpen && (
        <CompOnlyDialog
          policyName={policyName}
          compOnlyType={policy.compOnlyType ?? "STANDARD"}
          managePolicyUrl={
            isCorePolicy && !isStateFarm && policy.compOnlyType !== "EXCESS_TPL"
              ? managePolicyUrl
              : ""
          }
          onClose={compOnlyDialog.close}
        />
      )}

      {errorAlert && (
        <DownloadDocumentErrorAlert
          documentName={errorAlert}
          onClose={() => setErrorAlert(undefined)}
        />
      )}
    </>
  );
};
