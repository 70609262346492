import { useRef } from "react";
import { Highlight } from "../Highlight";
import { useActiveSection } from "../../hooks/useActiveSection";
import styles from "./InsuranceLayout.module.scss";
import { Section } from "../Section";
import { SubHeader } from "../SubHeader";
import { SectionHeader } from "../SectionHeader";
import { getPartnerHeadings } from "shared";
import { getVehiclesLabel } from "../../utils/vehicleUtils";
import { PartnerLogo } from "../PartnerLogo/PartnerLogo";
import { ContactBroker } from "./ContactBroker";
import { EffectiveFrom } from "../EffectiveFrom/EffectiveFrom";
import { Policy } from "@hagerty/self-service-components/api/types";
import { formatPolicyNumber } from "../../utils/formatPolicyNumber";
import { EnthusiastPolicyAlert } from "./EnthusiastPolicyAlert";

type InsuranceLayoutProps = {
  children: React.ReactNode;
  policy: Policy;
};

export function InsuranceLayout({ children, policy }: InsuranceLayoutProps) {
  const isCorePolicy = policy.source === "core";
  const { policyNumber, vehicles, effectiveDate, expirationDate } = policy;
  const partner = isCorePolicy ? policy.partner : undefined;

  const headerRef = useRef<HTMLHeadingElement | null>(null);
  useActiveSection(headerRef, "insurance");
  const vehicleNamesLabel = getVehiclesLabel(vehicles);

  const { heading, accessibleHeading } = getPartnerHeadings(
    partner,
    policy.state,
    !isCorePolicy
  );
  const ariaLabel = `${accessibleHeading} ${policyNumber} for ${vehicleNamesLabel}`;

  return (
    <Section ariaLabel={accessibleHeading} partner={partner}>
      {partner && <PartnerLogo partner={partner} />}
      <SectionHeader
        heading={heading}
        headerRef={headerRef}
        ariaLabel={ariaLabel}
        id="insurance"
      >
        {policyNumber && (
          <Highlight className={styles.highlight} ariaLabel="Policy number">
            #{formatPolicyNumber(policyNumber)}
          </Highlight>
        )}
      </SectionHeader>

      <SubHeader
        elements={[
          <EffectiveFrom
            effectiveDate={effectiveDate}
            expirationDate={expirationDate}
          />,
        ]}
      />

      {!isCorePolicy && <EnthusiastPolicyAlert />}

      {isCorePolicy && !!policy.broker && (
        <ContactBroker partner={partner} broker={policy.broker} />
      )}

      <dl className="grid grid-wrap collapsed-gutters login-data-table_row">
        {children}
      </dl>
    </Section>
  );
}
