import { GlobalFooter } from "../ui/GlobalFooter";
import {
  Customer,
  Environment,
  FeatureFlags,
  formatFeatureFlagsForTracking,
  TLD,
  URLs,
} from "shared";
import { AppContextProvider } from "./Providers/AppContext";
import { LogOnReadyContextProvider } from "./Providers/LogOnReadyContext";
import { PolicyContextProvider } from "./Providers/PolicyContext";
import { DriversClubContextProvider } from "./Providers/DriversClubContext";
import styles from "./App.module.scss";
import { Header } from "../ui/Header";
import cx from "clsx";
import { useRemoveHashBeforeUnload } from "../hooks/useRemoveHashBeforeUnload";
import { AppRoutes } from "../routing/AppRouting";
import { useEffect } from "react";
import { SessionTimeoutMonitor } from "../ui/SessionTimeoutMonitor/SessionTimeoutMonitor";
import { parseTimeoutEnvVar } from "../ui/SessionTimeoutMonitor/ParseTimeoutEnvVar";
import { mt } from "../utils/tracking";

const DEFAULT_SESSION_IDLE_TIME = 15 * 60 * 1000; // 15 minutes
const DEFAULT_SESSION_TIMEOUT_DURATION = 5 * 60 * 1000; // 5 minutes

const SESSION_IDLE_TIME = parseTimeoutEnvVar(
  process.env.REACT_APP_IDLE_SESSION_WARN_AFTER_IN_MILLISECONDS,
  DEFAULT_SESSION_IDLE_TIME
);

const SESSION_TIMEOUT_DURATION = parseTimeoutEnvVar(
  process.env.REACT_APP_IDLE_SESSION_WARNING_DURATION_IN_MILLISECONDS,
  DEFAULT_SESSION_TIMEOUT_DURATION
);

const { origin } = window.location;

const trackView = (emailHash: string, featureFlags: FeatureFlags) => {
  mt.trackView({
    user_type: "direct",
    logged_in: true,
    mobile_app: false,
    hashed_email: emailHash,
    pathname: window.location.pathname,
    test_experience: formatFeatureFlagsForTracking(featureFlags),
  });
};

export type AppProps = {
  customer: Customer;
  tld: TLD;
  contactId: string;
  environment: Environment;
  featureFlags: FeatureFlags;
  isStateFarmPartitioned: boolean;
  urls: URLs;
  emailHash: string;
  logoutId: string;
  isSso: boolean;
};

export function App({
  environment,
  tld,
  customer,
  contactId,
  isStateFarmPartitioned,
  featureFlags,
  urls,
  emailHash,
  logoutId,
  isSso,
}: AppProps) {
  useEffect(() => trackView(emailHash, featureFlags), []);

  useRemoveHashBeforeUnload();

  return (
    <AppContextProvider
      urls={urls}
      email={customer.email}
      tld={tld}
      environment={environment}
      country={customer.country}
      contactId={contactId}
      isStateFarmPartitioned={isStateFarmPartitioned}
      isSso={isSso}
      featureFlags={featureFlags}
      logoutId={logoutId}
    >
      <LogOnReadyContextProvider>
        <PolicyContextProvider>
          <DriversClubContextProvider>
            <a
              className={cx("sr-only", styles.skipMain)}
              href={`${origin}#main`}
            >
              Skip to main content
            </a>
            <SessionTimeoutMonitor
              idleTime={SESSION_IDLE_TIME}
              timeoutDuration={SESSION_TIMEOUT_DURATION}
            />
            <Header
              firstName={customer.firstName}
              lastName={customer.lastName}
              hagertyHomeUrl={urls.home}
              logoutUrl={urls.account.logout(origin, logoutId)}
            />
            <AppRoutes customer={customer} />
            <GlobalFooter />
          </DriversClubContextProvider>
        </PolicyContextProvider>
      </LogOnReadyContextProvider>
    </AppContextProvider>
  );
}
